import type { SVGProps } from 'react';

export function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="110 320 840 400"
      {...props}
    >
      <g>
        <path d="M119.72,477.88c0-22.67,18.97-41.47,56.42-41.47s52.73,16.24,52.73,37.62h-37.62c-.16-6.59-4.34-10.93-12.54-10.93-8.84,0-13.67,5.31-13.67,14.63,0,9.81,4.82,14.63,13.5,14.63s12.38-4.34,12.86-10.61h37.62c0,20.74-13.34,37.29-54.34,37.29s-54.98-21.06-54.98-41.15Z" />
        <path d="M236.75,438.02h45.33l-.64,22.83h.48c3.7-13.02,13.02-24.11,30.7-24.11,23.47,0,29.58,12.54,29.58,33.11,0,7.88-.32,16.56-.64,21.22h-36.97l.16-8.84c0-6.59-2.89-10.93-10.13-10.93s-12.54,5.79-12.54,16.24v29.9h-45.33v-79.41Z" />
        <path d="M458.91,481.26h-65.43c.48,8.84,3.22,13.82,14.95,13.82,8.68,0,12.54-3.54,12.86-8.04h37.62c-.32,18.32-14.79,31.99-53.53,31.99-44.85,0-57.23-20.1-57.23-40.51,0-28.77,22.83-42.12,57.23-42.12s53.53,13.02,53.53,37.29v7.56Zm-37.62-13.82c0-7.07-4.02-11.09-12.7-11.09s-13.02,3.7-14.47,13.5h27.17v-2.41Z" />
        <path d="M465.5,496.85c0-9.49,6.27-20.09,27.97-20.09h36.33v-5.14c0-5.31-3.38-9.97-11.74-9.97s-12.22,3.38-12.22,8.68c0,.32,0,.96,.16,1.61h-37.46c-.32-1.13-.48-3.21-.48-5.3,0-18,13.82-30.22,51.76-30.22,40.35,0,55.3,15.92,55.3,35.69v45.33h-45.33l.48-15.59h-.32c-3.54,9-13.02,17.2-32.63,17.2-24.43,0-31.83-12.22-31.83-22.18Zm64.3-6.75v-1.93h-13.66c-6.59,0-9.16,2.25-9.16,5.79s2.41,6.43,9,6.43c8.52,0,13.5-5.14,13.82-10.29Z" />
        <path d="M592.33,488.01v-20.74h-11.9v-29.26h9.64c5.63,0,8.68-2.09,9.49-8.68l1.13-9.32h36.97v18h31.19v29.26h-31.19v14.95c0,6.11,3.54,8.52,16.72,8.52,6.27,0,11.09-.48,13.02-.8v27.17c-3.05,.64-13.18,1.61-27.81,1.61-34.88,0-47.26-13.02-47.26-30.7Z" />
        <path d="M674.47,477.88c0-21.38,16.07-41.47,58.51-41.47s58.52,20.09,58.52,41.47-15.75,41.15-58.52,41.15-58.51-19.77-58.51-41.15Zm71.7-.16c0-8.04-3.38-14.63-13.18-14.63s-13.18,6.59-13.18,14.63,3.22,14.63,13.18,14.63,13.18-6.59,13.18-14.63Z" />
        <path d="M799.21,438.02h45.33l-.64,22.83h.48c3.7-13.02,13.02-24.11,30.7-24.11,23.47,0,29.58,12.54,29.58,33.11,0,7.88-.32,16.56-.64,21.22h-36.97l.16-8.84c0-6.59-2.89-10.93-10.13-10.93s-12.54,5.79-12.54,16.24v29.9h-45.33v-79.41Z" />
        <path d="M119.72,584.62c0-22.67,18.97-41.47,56.42-41.47s52.73,16.24,52.73,37.62h-37.62c-.16-6.59-4.34-10.93-12.54-10.93-8.84,0-13.67,5.31-13.67,14.63,0,9.81,4.82,14.63,13.5,14.63s12.38-4.34,12.86-10.61h37.62c0,20.74-13.34,37.29-54.34,37.29s-54.98-21.06-54.98-41.15Z" />
        <path d="M233.69,603.59c0-9.49,6.27-20.09,27.97-20.09h36.33v-5.14c0-5.31-3.38-9.97-11.74-9.97s-12.22,3.38-12.22,8.68c0,.32,0,.96,.16,1.61h-37.46c-.32-1.13-.48-3.21-.48-5.3,0-18,13.82-30.22,51.76-30.22,40.35,0,55.3,15.92,55.3,35.69v45.33h-45.33l.48-15.59h-.32c-3.54,9-13.02,17.2-32.63,17.2-24.43,0-31.83-12.22-31.83-22.18Zm64.3-6.75v-1.93h-13.66c-6.59,0-9.16,2.25-9.16,5.79s2.41,6.43,9,6.43c8.52,0,13.5-5.14,13.82-10.29Z" />
        <path d="M351.85,544.75h45.33l-.48,22.02h.32c4.66-12.22,14.31-23.63,32.63-23.63s28.77,8.36,31.67,23.31h.32c4.34-12.22,15.75-23.31,32.63-23.31,23.63,0,32.31,13.02,32.31,31.99v49.03h-45.33v-36.81c0-6.43-2.25-9.49-8.52-9.49-5.31,0-10.77,3.38-10.77,12.06v34.24h-45.33v-36.81c0-6.43-2.25-9.49-8.68-9.49-5.14,0-10.77,3.38-10.77,12.06v34.24h-45.33v-79.41Z" />
        <path d="M535.59,544.75h45.33l-.48,15.43h.32c5.14-10.13,15.11-17.04,33.28-17.04,25.72,0,40.03,15.59,40.03,39.38,0,28.78-16.4,43.24-41.15,43.24-17.52,0-27.17-8.04-31.99-14.79h-.32c0,3.05,.32,10.77,.32,14.47v30.54h-45.33v-111.24Zm73.14,39.22c0-9-4.5-14.15-13.02-14.15s-14.63,4.82-14.79,15.27v4.18c1.29,5.14,6.91,9.81,14.79,9.81,8.36,0,13.02-5.14,13.02-15.11Z" />
      </g>
      <path d="M714.16,659.99c-.21,0-.43,0-.64,0-7.23-.06-13.91-1.2-20.45-3.47l-2.31-.8-13.13-111.89,6.86,4.32c7.14,4.49,14.39,7.86,22.17,10.31,6.94,2.18,14.15,3.56,22.05,4.23,14.83,1.26,29.89-.25,44.45-1.72,14.7-1.48,29.9-3.01,44.84-1.67,8.14,.73,15.54,2.22,22.63,4.57,7.97,2.64,15.33,6.27,22.51,11.11l1.99,1.34-1.81,15.45c6.48,.61,13.05,.83,19.45,1.04,19.16,.63,38.96,1.29,58.79,12.24l18.7,10.33-21.09-2.95c-8.04-1.13-16.89-.44-27.07,2.1-9.29,2.32-18.85,5.94-28.1,9.45-16.34,6.19-33.16,12.57-50.16,12.57-2.93,0-5.86-.19-8.8-.6l-3.35-.47v-7.81c-16.01,2.47-32.18,9.2-47.86,15.74-13.13,5.47-26.7,11.13-40.03,14.19-7.05,1.62-13.49,2.41-19.67,2.41Zm-16.27-10.19c10.23,3.06,21.19,3.08,34.22,.09,12.69-2.91,25.37-8.2,38.79-13.79,17.59-7.33,35.77-14.91,54.29-16.84l4.28-.45v9.67c7.14,.54,14.94-.27,23.72-2.46,9.29-2.32,18.85-5.94,28.1-9.45,12.73-4.83,25.75-9.76,38.91-11.71-12.33-3.34-24.77-3.75-37.68-4.17-7.83-.26-15.93-.53-23.98-1.51l-3.85-.47,2.17-18.48c-26.83-17.07-54.1-14.33-82.92-11.43-11.04,1.11-22.22,2.24-33.52,2.24-17.46,0-35.21-2.68-53.2-12.25l10.68,91Z" />
    </svg>
  );
}
