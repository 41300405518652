import type { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { TopBar } from './TopBar';
import { ToastError } from './ToastError';

interface Props {
  isLoggedIn: boolean;
  isHidden?: boolean;
  error?: { message: string; timestamp: number } | undefined;
}

export function MainContainer({
  children,
  isLoggedIn,
  isHidden = false,
  error,
}: PropsWithChildren<Props>) {
  return (
    <>
      <ToastError message={error?.message} timestamp={error?.timestamp} />
      <div
        className={classNames('h-screen w-full flex-col', {
          flex: !isHidden,
          hidden: isHidden,
        })}
      >
        <TopBar isLoggedIn={isLoggedIn} />
        <main className="relative flex w-full flex-1 overflow-hidden">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            className="absolute h-full w-full object-cover"
            src="https://imagedelivery.net/sntMgBSUxwGLOssxSIidlA/0d0ce382-3835-47d1-9bc3-2e861d3f3a00/public"
            crossOrigin="anonymous"
          />
          <div className="absolute h-full w-full overflow-scroll">
            {children}
            <div className="mt-8 flex justify-center gap-4 p-4 text-xs font-bold text-cc-50 md:mt-0 md:justify-end md:pt-0">
              <a
                href="https://creatorcamp.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://creatorcamp.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms Of Service
              </a>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
