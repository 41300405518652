import { Form, Link, useNavigate } from '@remix-run/react';
import { Button } from './Button';
import { Logo } from '../images/Logo';
import { LOGIN, LOGOUT } from '../analytics-events';

interface Props {
  isLoading?: boolean;
  isLoggedIn?: boolean;
  login?(): void;
}

export function TopBar({ isLoading, isLoggedIn = true }: Props) {
  const navigate = useNavigate();

  return (
    <div className="flex w-full items-center justify-between p-3">
      <div className="h-12 w-24">
        <Link to="/">
          <Logo className="fill-white" />
        </Link>
      </div>
      <div className="w-24">
        {!isLoggedIn ? (
          <Button
            analyticsEvent={LOGIN}
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => {
              navigate('login');
            }}
          >
            Login
          </Button>
        ) : (
          <Form method="post" action="/logout">
            <Button
              analyticsEvent={LOGOUT}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Logout
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
}
