import { X } from 'phosphor-react';
import { useEffect, useState } from 'react';

interface Props {
  message?: string;
  timestamp?: number;
}

export function ToastError({ message, timestamp }: Props) {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [handledTimestamp, setHandledTimestamp] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (handledTimestamp === timestamp) {
      return;
    }

    setIsHidden(!message);
    setHandledTimestamp(timestamp);
  }, [handledTimestamp, message, timestamp]);

  if (isHidden) {
    return null;
  }

  return (
    <div
      className="fixed top-2 z-10 flex w-full justify-center px-2"
      role="alert"
    >
      <div className="flex w-full items-center rounded border border-rose-600 bg-rose-900 py-2 pl-3 pr-2 text-rose-100 shadow-lg md:max-w-[66%] xl:max-w-[33%]">
        <div className="flex-1 select-none leading-none">{message}</div>
        <button
          className="rounded border border-rose-600 bg-rose-800 p-2 shadow-md hover:bg-rose-500"
          onClick={() => {
            setIsHidden(true);
          }}
        >
          <X weight="bold" />
        </button>
      </div>
    </div>
  );
}
