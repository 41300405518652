import type { DataFunctionArgs, LoaderFunction } from '@remix-run/cloudflare';
import type { Context } from '../@types/Context';
import type { DataFunctionArgsWithAppContext } from '../@types/DataFunctionArgsWithAppContext';
import type { LoaderFunctionWithTypedContext } from '../@types/LoaderFunctionWithTypedContext';

export function sentryWrapLoader(
  func: LoaderFunctionWithTypedContext
): LoaderFunction {
  return async (args: DataFunctionArgs) => {
    const { context } = args as any as DataFunctionArgsWithAppContext;
    const { sentry } = context as any as Context;
    const { request } = args;
    const url = new URL(request.url);

    try {
      const transaction = sentry.startTransaction({
        name: url.pathname,
        op: 'loader',
      });
      context.transaction = transaction;

      const result = await func(args as any);

      transaction.finish();

      return result;
    } catch (error) {
      if (context.sentry) {
        (context as any as Context).sentry.captureException(error);
      }

      throw error;
    }
  };
}
